import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const MenuHeader = styled.div`
  margin-bottom: 1rem;

  ${({ inAdminView }) =>
    inAdminView &&
    css`
      margin: 1rem;
    `}

  ${breakpoint("desktop")`
    gap: 0.3rem;
    top: 2px;
    position: relative;

    align-items: center;
    display: flex;
    gap: 0.1rem;
    justify-content: flex-end;
    padding: 0 0 1.3rem;
    margin: -7px 0 4px;
    height: 3rem;
  `}
`

export const DesktopHide = styled.div`
  display: inline-block;

  ${breakpoint("widescreen")`
    display: none;
  `}
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.1rem;

  ${breakpoint("widescreen")`
    gap: 0.3rem;
    top: 2px;
    position: relative;
    dsplay: flex;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 0.8em;

  ${breakpoint("tablet")`
    padding: 0;
  `}

  ${breakpoint("desktop")`
    padding: 1.5em 0em;
  `}

  ${breakpoint("widescreen")`
    padding: 1rem 0.8rem;
  `}
`
