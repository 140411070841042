import React, { useContext } from "react"
import MenuContext from "@context/MenuContext"
import UserContext from "@context/UserContext"
import { Container, Header, CancelLink, EmployerLink } from "./styles"
import CloseButton from "@localComponents/close-button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faCheck } from "@fortawesome/free-solid-svg-icons"

const MobileEmployerSwitcher = (props) => {
  const { showMobileEmployerList, toggleMobileEmployerList, toggleMenuAndMobileEmployerList, changeEmployer } =
    useContext(MenuContext)
  const { employer, employers } = useContext(UserContext).menu

  const listStyle = {
    margin: "0",
    padding: "1.2em",
    listStyle: "none"
  }

  const listItemStyle = {
    marginBottom: "1.5rem"
  }

  return (
    <Container show={showMobileEmployerList}>
      <Header>
        <CancelLink to="#" onClick={toggleMobileEmployerList}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>Cancel</span>
        </CancelLink>
        <CloseButton id="mobileCancelApplicationListButton" onClick={toggleMenuAndMobileEmployerList} mobileOnly />
      </Header>

      <ul style={listStyle}>
        {employers.map((list_employer) => {
          const isCurrent = list_employer.id === employer.id
          return (
            <li style={listItemStyle} key={list_employer.id}>
              <EmployerLink
                active={isCurrent}
                onClick={changeEmployer}
                data-id={list_employer.id}
                data-brand={list_employer.brand}
              >
                <span>{list_employer.name}</span>
                {isCurrent && <FontAwesomeIcon icon={faCheck} />}
              </EmployerLink>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default MobileEmployerSwitcher
