import logo from "../assets/logos/smallerearth.svg"
import colouredLogo from "../assets/logos/smallerearth_coloured.svg"

const smallerearth = {
  name: "smallerearth",
  friendlyName: "Smaller Earth",
  tagline: "Find your future staff.",
  links: {
    appBase: {
      development: "http://smallerearth.test:3001",
      staging: "https://emf-staging.smallerearth.com",
      production: "https://camps.smallerearth.com"
    }
  },
  hostWord: "Camp",
  logo: logo,
  colouredLogo: colouredLogo
}

export default smallerearth
