import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  ItemList,
  SubList,
  SubListTitle,
  SubListTitleLeft,
  SubListTitleRight,
  StyledLink,
  SubLink,
  StyledExternalLink,
  SubItemText,
  Item,
  LockedSubLink,
  LockedLink,
  MenuIconOuter,
  SpinnerOuter,
  ItemRight,
  ItemRightOuter
} from "./styles"

import { withChecklist } from "@hoc/checklist"
import { getBrand } from "@helpers/siteHelpers"
import MenuContext from "@context/MenuContext"
import UserContext from "@context/UserContext"
import { NavIcons, StatusIcons, LoadingSpinner, Badge, Separator } from "@smaller-earth/postcard-components"
import Arrow from "@localComponents/arrow"
import { ProfileInfo, Top, LogoutButton } from "@localComponents/menu"
import { Tooltip } from "react-tippy"
import "react-tippy/dist/tippy.css"

const getNavIcon = (item) => {
  if (item.system_name === undefined) return
  const iconName = formatIconName(item.system_name)
  return NavIcons[iconName + "Icon"]
}

const getStatusIcon = (item) => {
  if (item.status === undefined) return
  const iconName = item.status[0].toUpperCase() + item.status.slice(1)

  return StatusIcons[iconName + "Icon"]
}

const formatIconName = (name) => {
  name = name.replace(/_/g, " ")
  name = name.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase()
  })

  return name.replace(/\s/g, "")
}

const Items = (props) => {
  const { toggleMenu, toggleMobileEmployerList } = useContext(MenuContext)
  const { employers, helpSupportUrl, showFeeSheet } = useContext(UserContext).menu
  const { checklist, currentProfileChecklistSection } = props
  const { friendlyName, logo, hostWord } = getBrand()
  const [openedSubList, setOpenedSubList] = useState([])

  const toggleSubList = (item) => {
    if (openedSubList?.includes(item.system_name)) {
      setOpenedSubList(openedSubList.filter((i) => i !== item.system_name))
    } else {
      setOpenedSubList((prevState) => {
        return [...prevState, item.system_name]
      })
    }
  }

  useEffect(() => {
    const informationChecklist = checklist?.filter((item) => item.system_name === "information")[0]
    if (informationChecklist?.initiallyExpanded === true) setOpenedSubList(["information"])
  }, [checklist])

  return (
    <>
      <Top src={logo} logoAlt={friendlyName} toggleMenu={toggleMenu} />
      {checklist?.length === 0 && (
        <SpinnerOuter>
          <LoadingSpinner variant="default" />
        </SpinnerOuter>
      )}

      <ProfileInfo mobileOnly />
      {checklist?.length > 0 && (
        <ItemList>
          <Item>
            <StyledLink exact to="/" activeClassName="active" onClick={toggleMenu}>
              <MenuIconOuter>
                <NavIcons.HomeIcon size="1x" />
                <span style={{ paddingTop: "1px" }}>Home</span>
              </MenuIconOuter>
            </StyledLink>
          </Item>
          {checklist.filter((item) => item.profile_position === "outside").length > 0 && (
            <Separator mobileOnly={false} shadow halfMargin navbar size="small" />
          )}
          {checklist
            .filter((item) => item.profile_position === "outside")
            .map((item, index, arr) => {
              const ItemNavIcon = getNavIcon(item)
              const showSubList = openedSubList?.includes(item.system_name)
              const activeChecklist = currentProfileChecklistSection === item.system_name
              const profileBadge = item.items.filter((item) => item.percentage !== 100).length

              return (
                <>
                  {item.seperator === true && <Separator mobileOnly={false} shadow halfMargin navbar size="small" />}
                  {item.items.length > 0 ? (
                    <Item>
                      <SubListTitle onClick={() => toggleSubList(item)} className={activeChecklist ? "active" : ""}>
                        <SubListTitleLeft>
                          <MenuIconOuter>
                            {ItemNavIcon && <ItemNavIcon size="1x"></ItemNavIcon>}
                            <span>{item.name}</span>
                          </MenuIconOuter>
                        </SubListTitleLeft>

                        <SubListTitleRight>
                          {profileBadge > 0 && (
                            <Badge useTheme fontWeight="600">
                              {profileBadge}
                            </Badge>
                          )}
                          <ItemRightOuter>
                            <Arrow direction={showSubList ? "up" : "down"} menu />
                          </ItemRightOuter>
                        </SubListTitleRight>
                      </SubListTitle>
                      <SubList showSubList={showSubList}>
                        {item.items.length > 0 &&
                          item.items.map((item, index) => {
                            const ItemStatusIcon = getStatusIcon(item)
                            return (
                              <Item key={`${item.system_name}_${index}`}>
                                {!!item.locked ? (
                                  <Tooltip title="This segment has been locked." delay="500">
                                    <LockedSubLink>
                                      {ItemStatusIcon && <ItemStatusIcon size="sm"></ItemStatusIcon>}
                                      <SubItemText>{item.name}</SubItemText>
                                    </LockedSubLink>
                                  </Tooltip>
                                ) : (
                                  <SubLink to={item.path} activeClassName="active" onClick={toggleMenu}>
                                    {ItemStatusIcon && <ItemStatusIcon status={item.status} size="sm"></ItemStatusIcon>}
                                    <SubItemText>{item.name}</SubItemText>
                                  </SubLink>
                                )}
                              </Item>
                            )
                          })}
                      </SubList>
                    </Item>
                  ) : (
                    <Item key={`${item.system_name}_${index}`} extraMargin={arr.length - 1 === index}>
                      {!!item.locked ? (
                        <LockedLink hasicon="true">
                          <MenuIconOuter>
                            {ItemNavIcon && <ItemNavIcon size="1x" />}
                            <span style={{ paddingTop: "1px" }}>{item.name}</span>
                          </MenuIconOuter>
                          <StatusIcons.LockedIcon status="locked" size="1x"></StatusIcons.LockedIcon>
                        </LockedLink>
                      ) : (
                        <StyledLink to={item.path} activeClassName="active" hasicon="true" onClick={toggleMenu}>
                          <MenuIconOuter>
                            {ItemNavIcon && <ItemNavIcon size="1x" />}
                            <span style={{ paddingTop: "1px" }}>{item.name}</span>
                          </MenuIconOuter>
                          <ItemRight>
                            {item.badge_count > 0 && <Badge fontWeight="600">{item.badge_count}</Badge>}
                            <ItemRightOuter>&nbsp;</ItemRightOuter>
                          </ItemRight>
                        </StyledLink>
                      )}
                    </Item>
                  )}
                </>
              )
            })}

          <Separator mobileOnly={false} shadow halfMargin navbar size="small" />
          {helpSupportUrl && (
            <Item>
              <StyledExternalLink href={helpSupportUrl} target="_blank" info="1" onClick={toggleMenu}>
                <MenuIconOuter>
                  <NavIcons.HelpIcon size="1x" />
                  <span>Help &amp; Information</span>
                </MenuIconOuter>
              </StyledExternalLink>
            </Item>
          )}
          {!!showFeeSheet && (
            <Item>
              <StyledLink to="/fee-sheet" activeClassName="active" info="1" onClick={toggleMenu}>
                <MenuIconOuter>
                  <NavIcons.FeeSheetIcon size="1x" />
                  <span>Fee Sheet</span>
                </MenuIconOuter>
              </StyledLink>
            </Item>
          )}
          <Item>
            <StyledLink to="/settings" activeClassName="active" info="1" onClick={toggleMenu}>
              <MenuIconOuter>
                <NavIcons.SettingsIcon size="1x" />
                <span>Settings</span>
              </MenuIconOuter>
            </StyledLink>
          </Item>

          <Separator mobileOnly={true} shadow halfMargin navbar size="small" />

          {employers?.length > 1 && (
            <Item mobileOnly={true}>
              <StyledLink to="#" onClick={toggleMobileEmployerList} activeClassName="none" info="1">
                <MenuIconOuter>
                  <NavIcons.SwitchIcon size="1x" />
                  <span>Switch {hostWord}</span>
                </MenuIconOuter>
              </StyledLink>
            </Item>
          )}

          <Item mobileOnly={true}>
            <LogoutButton>
              <MenuIconOuter>
                <NavIcons.LogoutIcon size="1x" />
                <span>Log out</span>
              </MenuIconOuter>
            </LogoutButton>
          </Item>
        </ItemList>
      )}
    </>
  )
}

Items.propTypes = {
  checklist: PropTypes.array.isRequired,
  profileStatus: PropTypes.string
}

Items.defaultProps = {
  checklist: [],
  profileStatus: ""
}

export default withChecklist()(Items)
