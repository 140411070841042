import React from "react"
import { withRouter } from "react-router-dom"
import { StyledButton } from "./styles"

const LogoutButton = ({ children, ...rest }) => {
  const logoutUser = (event) => {
    event.preventDefault()
    window.location.href = "/logout"
  }

  return <StyledButton onClick={logoutUser}>{children}</StyledButton>
}

export default withRouter(LogoutButton)
