import { themes } from "@smaller-earth/postcard-components"
import brands from "../brands"

const brandFromHostName = detectBrandFromHostname()

export function getBrand(brand) {
  if (brand === undefined) brand = brandFromHostName
  return brands[brand]
}

export function getTheme() {
  return themes[brandFromHostName]
}

function detectBrandFromHostname() {
  let hostname = window.location.hostname.toLowerCase()
  let brand

  switch (true) {
    case /americancampco/.test(hostname):
      brand = "americancampco"
      break
    case /smallerearth/.test(hostname):
      brand = "smallerearth"
      break
    case /campleaders/.test(hostname):
      brand = "campleaders"
      break
    case /resortleaders/.test(hostname):
      brand = "resortleaders"
      break
    case /adventurechina/.test(hostname):
      brand = "adventurechina"
      break
    case /adventureasia/.test(hostname):
      brand = "adventureasia"
      break
    case /campcanada/.test(hostname):
      brand = "campcanada"
      break
    case /canago/.test(hostname):
      brand = "canago"
      break
    default:
      brand = "smallerearth"
  }

  return brand
}

export function getEnvironment() {
  let env = process.env.REACT_APP_ENV
  if (env === undefined) env = "production"

  return env
}
