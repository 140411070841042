import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Container } from "./styles"
import { CloseButton, Logo } from "@smaller-earth/postcard-components"

const Top = (props) => {
  const { src, logoAlt, toggleMenu } = props

  const closeMenu = (event) => {
    event.preventDefault()
    toggleMenu()
  }

  return (
    <Container>
      <Link to="/">
        <Logo src={src} alt={logoAlt} />
      </Link>
      <CloseButton id="mobileMenuButton" onClick={closeMenu} mobileOnly style={{ height: "32px", width: "32px" }} />
    </Container>
  )
}

Top.propTypes = {
  toggleMenu: PropTypes.func
}

export default Top
