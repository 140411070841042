import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import MenuContext from "@context/MenuContext"
import UserContext from "@context/UserContext"
import { Container, Inner, Text, Name, Subtext, StyledButton, OptionsPopupOuter } from "./styles"
import { Avatar, NavIcons, OptionsPopup } from "@smaller-earth/postcard-components"
import { EmployerSwitcher, LogoutButton } from "@localComponents/menu"
import { getBrand } from "@helpers/siteHelpers"

const ProfileInfo = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [employerSwitcherOpen, setEmployerSwitcherOpen] = useState(false)
  const employerSwitcherClassName = "employers_list_box"

  const { changeEmployer } = useContext(MenuContext)
  const { employer, employers } = useContext(UserContext).menu
  const { current_employer_name, current_employer_stage, avatar_exists, avatar_path, first_name, last_name } =
    useContext(UserContext).user

  const linkStyle = {
    textDecoration: "none"
  }

  useEffect(() => {
    const handleWindowClick = (event) => {
      const outsideOptionsBox = !event.target.closest(".profile_info_options_box")
      if (outsideOptionsBox) setMenuOpen(false)
    }

    if (menuOpen) {
      window.addEventListener("click", handleWindowClick)
    } else {
      window.removeEventListener("click", handleWindowClick)
    }

    return () => window.removeEventListener("click", handleWindowClick)
  }, [menuOpen])

  useEffect(() => {
    const handleWindowClick = (event) => {
      const outsideOptionsBox = !event.target.closest(`.${employerSwitcherClassName}`)
      if (outsideOptionsBox) setEmployerSwitcherOpen(false)
    }

    if (employerSwitcherOpen) {
      window.addEventListener("click", handleWindowClick)
    } else {
      window.removeEventListener("click", handleWindowClick)
    }

    return () => window.removeEventListener("click", handleWindowClick)
  }, [employerSwitcherOpen])

  const toggleMenu = (event) => {
    event.preventDefault()
    setMenuOpen(!menuOpen)
  }

  const toggleEmployerSwitcher = (event) => {
    event.preventDefault()
    setEmployerSwitcherOpen(!employerSwitcherOpen)
  }

  return (
    <>
      <Container mobileOnly={props.mobileOnly}>
        <Inner>
          <Link to="/public-profile" style={linkStyle}>
            <Avatar
              avatarExists={avatar_exists}
              src={avatar_path}
              alt="Avatar"
              firstName={first_name}
              lastName={last_name}
              size="small"
              plain={true}
            />
          </Link>
          <Text>
            <Name>{current_employer_name}</Name> <Subtext>{current_employer_stage}</Subtext>
          </Text>
        </Inner>

        <StyledButton href="" onClick={toggleMenu} desktopOnly>
          <NavIcons.EllipsisIcon size="lg" color="#ffffff" />
        </StyledButton>
      </Container>

      {menuOpen && (
        <OptionsPopupOuter>
          <OptionsPopup.Box className="profile_info_options_box">
            <OptionsPopup.List>
              {employer && employers.length > 1 && (
                <OptionsPopup.ListItem>
                  <OptionsPopup.Link onClick={toggleEmployerSwitcher}>
                    <OptionsPopup.LinkText>
                      <NavIcons.SwitchIcon size="lg" color="#555555"></NavIcons.SwitchIcon>
                      <span>Switch {getBrand().hostWord}</span>
                    </OptionsPopup.LinkText>
                  </OptionsPopup.Link>
                  {employerSwitcherOpen && (
                    <EmployerSwitcher
                      employers={employers}
                      currentEmployer={employer}
                      className={employerSwitcherClassName}
                      changeEmployer={changeEmployer}
                    />
                  )}
                </OptionsPopup.ListItem>
              )}

              <OptionsPopup.ListItem>
                <LogoutButton>
                  <NavIcons.LogoutIcon size="lg" color="#555555" />
                  <span>Log out</span>
                </LogoutButton>
              </OptionsPopup.ListItem>
            </OptionsPopup.List>
          </OptionsPopup.Box>
        </OptionsPopupOuter>
      )}
    </>
  )
}

export default ProfileInfo
