import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { darken } from "polished"

export const Container = styled.div`
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem 0;
  background-color: ${(props) => props.theme.colors.primary};
  gap: 0.5rem;
  margin: 0 0.25rem 1.5rem;

  ${breakpoint("widescreen")`
    padding: 1rem 1.4rem 1rem 2rem;
    margin: 0;
    box-shadow: 0px -4px 8px 1px ${(props) => darken(0.05, props.theme.colors.primary)};
    width: 25dvw;
    max-width: 360px;
  `}

  ${(props) =>
    props.mobileOnly &&
    css`
      display: flex;

      ${breakpoint("widescreen")`
      display: none;
    `}
    `}


  ${(props) =>
    !props.mobileOnly &&
    css`
      display: none;

      ${breakpoint("widescreen")`
      display: flex;
    `}
    `}
`

export const Inner = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`

export const Text = styled.div`
  color: ${(props) => props.theme.colors.body};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;

  ${breakpoint("desktop")`
    width: 75%;
    max-width: 14vw;
  `}
`

export const Name = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.4rem;
  a {
    color: #ffffff;
  }
`

export const ApplicationId = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  background: ${(props) => props.theme.colors.primaryContrasted};
  padding: 2px 3px;
  border-radius: 2px;
`

export const Subtext = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.2rem;
  color: #f6f6f6;

  ${breakpoint("desktop")`
    font-size: 14px;
  `}

  ${breakpoint("widescreen")`
    font-size: 15px;
  `}
`

export const StyledButton = styled.a`
  display: none;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;

  &:hover {
    background: ${(props) => props.theme.colors.primaryContrasted};
  }

  ${(props) =>
    props.desktopOnly &&
    breakpoint("widescreen")`
    display: flex;
  `}
`

export const OptionsPopupOuter = styled.div`
  position: fixed;
  bottom: 5rem;
  left: 1.5dvw;
  width: 21.5dvw;
  max-width: 300px;
`
