import React from "react"
import { Container, Circle } from "./styles"

const LoadingSpinner = () => (
  <Container>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
  </Container>
)

export default LoadingSpinner
