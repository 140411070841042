import logo from "../assets/logos/canago.svg"
import colouredLogo from "../assets/logos/canago_coloured.svg"

const canago = {
  name: "canago",
  friendlyName: "Canago",
  tagline: "Your Canadian Working Holiday.",
  links: {
    appBase: {
      development: "http://canago.test:3001",
      staging: "https://emf-staging.canago.com",
      production: "https://camps.canago.com"
    }
  },
  hostWord: "Resort",
  logo: logo,
  colouredLogo: colouredLogo
}

export default canago
