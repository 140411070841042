import styled from "styled-components"
import { darken } from "polished"
import breakpoint from "styled-components-breakpoint"

export const StyledButton = styled.a`
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: transparent;
  font-size: inherit;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  &:hover {
    background: ${(props) => darken(0.04, props.theme.colors.lightGrey)};
  }

  font-size: 20px;
  color: white;
  ${() => breakpoint("desktop")`
    font-size: 1rem;
    color: inherit;
  `}
`
