import React, { Component } from "react"
import PropTypes from "prop-types"
import { postRequest } from "@helpers/apiHelpers"
import { getBrand, getEnvironment, getTheme } from "@helpers/siteHelpers"
import { titleize } from "@helpers/stringHelpers"
import UserContext from "@context/UserContext"
import { MenuProvider } from "@context/MenuContext"
import { EnvironmentRibbon } from "@smaller-earth/postcard-components"
import HeaderBar from "@localComponents/header-bar"
import { Items, MobileEmployerSwitcher, ProfileInfo } from "@localComponents/menu"
import { GlobalStyles, Container, Aside, Main, Inner, Nav, MainWrapper } from "./styles"
import { Helmet } from "react-helmet"
import { withAlert } from "react-alert"
import { withCookies, Cookies } from "react-cookie"
import { instanceOf } from "prop-types"
class Layout extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
      showMobileEmployerList: false
    }
  }

  toggleMenu = (event) => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu
    }))
  }

  toggleMobileEmployerList = (event) => {
    if (event !== undefined) event.preventDefault()

    this.setState((prevState) => ({
      showMobileEmployerList: !prevState.showMobileEmployerList
    }))
  }

  toggleMenuAndMobileEmployerList = (event) => {
    event.preventDefault()
    this.toggleMobileEmployerList()
    this.toggleMenu()
  }

  changeEmployer = (event) => {
    event.preventDefault()

    const { id, brand } = event.currentTarget.dataset
    const brandObj = getBrand(brand)
    const { access_token, current_user_masquerading, current_user_masquerading_id } = this.context.user

    let authMethod = null
    const cookiesAuthState = this.props.cookies.get("authState")
    const sessionAuthState = sessionStorage["authState"]
    if (cookiesAuthState && cookiesAuthState.isAuthenticated) {
      authMethod = "cookies"
    } else if (sessionAuthState) {
      const authState = JSON.parse(sessionAuthState)
      if (authState.isAuthenticated) authMethod = "session"
    }

    postRequest("/switch_employers/request_token", access_token, JSON.stringify({ employer_id: id }))
      .then((data) =>
        window.open(
          `${brandObj.links.appBase[process.env.REACT_APP_ENV]}/switch-employer/${
            data.switch_token
          }/${authMethod}/${id}?current_user_masquerading=${current_user_masquerading}&current_user_masquerading_id=${current_user_masquerading_id}`,
          "_self"
        )
      )
      .catch((error) => this.props.alert.error(`Could not switch employer: ${error}`))
  }

  render() {
    const { toggleMenu, toggleMobileEmployerList, toggleMenuAndMobileEmployerList, changeEmployer } = this
    const { employers } = this.context.menu
    const { in_admin_view } = this.context.user
    const { title, homePage, children, profileChecklist, simpleFormLayout } = this.props
    const { showMenu, showMobileEmployerList } = this.state

    const { name, friendlyName } = getBrand()
    const environment = getEnvironment()
    const theme = getTheme()
    const { current_user_masquerading } = this.context.user

    let pageTitle = title ? `${title} | ${friendlyName}` : friendlyName
    if (["development", "staging"].includes(environment)) pageTitle = `[${environment.toUpperCase()}] ${pageTitle}`

    return (
      <Container>
        <Helmet defer={false}>
          <title>{pageTitle}</title>
          <meta name="theme-color" content={theme.colors.brand} />
          <link rel="icon" href={`/assets/${name}/favicon.png`} />
          <script src="https://js.stripe.com/v3"></script>
          {environment === "production" && !current_user_masquerading && (
            <script>
              {`
                (function (w, d, e, v, o, l, t){w['ToldWidget']=o; w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; w[o].l=1 * new Date();l=document.createElement(e);t=document.getElementsByTagName(e)[0]; l.async=1; l.src=v; t.parentNode.insertBefore(l, t);})(window, document, 'script', 'https://scripts.told.club/sdk/sdk.js', 'told');
                told('init', '67408b869c235046cd66c2f6');
              `}
            </script>
          )}
        </Helmet>
        <GlobalStyles simpleForm={simpleFormLayout} />

        {environment !== "production" && <EnvironmentRibbon environment={titleize(environment)} />}

        <MenuProvider
          value={{
            showMenu,
            showMobileEmployerList,
            toggleMenu,
            toggleMobileEmployerList,
            toggleMenuAndMobileEmployerList,
            changeEmployer
          }}
        >
          {!in_admin_view && (
            <Aside show={showMenu} className="ho-sidebar">
              <Inner>
                <Nav>
                  <Items toggleMenu={toggleMenu} currentProfileChecklistSection={profileChecklist} />
                </Nav>
                <ProfileInfo />
              </Inner>

              {employers?.length > 1 && <MobileEmployerSwitcher />}
            </Aside>
          )}

          <MainWrapper>
            <HeaderBar inAdminView={in_admin_view} homePage={homePage} />
            <Main simpleForm={simpleFormLayout}>{children}</Main>
          </MainWrapper>
        </MenuProvider>
      </Container>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  cookies: instanceOf(Cookies).isRequired
}

Layout.defaultProps = {
  children: null,
  title: null
}

export default withCookies(withAlert()(Layout))
