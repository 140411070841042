import logo from "../assets/logos/campcanada.svg"
import colouredLogo from "../assets/logos/campcanada_coloured.svg"

const campcanada = {
  name: "campcanada",
  friendlyName: "Camp Canada",
  tagline: "Find your future staff.",
  links: {
    appBase: {
      development: "http://campcanada.test:3001",
      staging: "https://emf-staging.campcanada.ca",
      production: "https://camps.campcanada.ca"
    }
  },
  hostWord: "Camp",
  logo: logo,
  colouredLogo: colouredLogo
}

export default campcanada
