import logo from "../assets/logos/adventureasia.svg"
import colouredLogo from "../assets/logos/adventureasia_coloured.svg"

const adventureasia = {
  name: "adventureasia",
  friendlyName: "Adventure Asia",
  tagline: "The gateway to your teaching adventure.",
  links: {
    appBase: {
      development: "http://adventureasia.test:3001",
      staging: "https://emf-staging.adventureasia.com",
      production: "https://camps.adventureasia.com"
    }
  },
  hostWord: "School",
  logo: logo,
  colouredLogo: colouredLogo
}

export default adventureasia
