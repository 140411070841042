import React, { useContext } from "react"
import { Link } from "react-router-dom"
import {
  OpenMessagesButton,
  OpenNotificationsButton,
  OpenMenuButton,
  StaffStats,
  Logo
} from "@smaller-earth/postcard-components"
import { MenuHeader, DesktopHide, Buttons, Wrapper } from "./styles"
import MenuContext from "@context/MenuContext"
import UserContext from "@context/UserContext"
import { getBrand } from "@helpers/siteHelpers"

const HeaderBar = (props) => {
  const { friendlyName, colouredLogo } = getBrand()
  const { toggleMenu } = useContext(MenuContext)
  const { messagingEnabled, messageCount, notificationCount, staffCounts } = useContext(UserContext).menu

  return (
    <MenuHeader inAdminView={props.inAdminView}>
      {staffCounts && <StaffStats desktopOnly={true} staffCounts={staffCounts} homePage={props.homePage} />}

      <Wrapper className="ho-header-wrapper">
        <Link to="/">
          <Logo src={colouredLogo} alt={friendlyName} tabletOnly medium />
        </Link>

        <Buttons>
          <OpenNotificationsButton notificationCount={notificationCount} />
          {messagingEnabled && <OpenMessagesButton messageCount={messageCount} />}
          {!props.inAdminView && (
            <DesktopHide>
              <OpenMenuButton toggleMenu={toggleMenu} />
            </DesktopHide>
          )}
        </Buttons>
      </Wrapper>

      {staffCounts && !props.inAdminView && <StaffStats mobileOnly={true} staffCounts={staffCounts} homePage={true} />}
    </MenuHeader>
  )
}

export default HeaderBar
