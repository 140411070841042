import React, { useContext } from "react"
import UserContext from "@context/UserContext"
import LoadingLayout from "@layouts/loading-layout"
import LoadingBox from "@localComponents/loading-box"
import LoadingSpinner from "@localComponents/loading-spinner"
import Message from "./message"
import Layout from "@layouts/layout"

const Loading = (props) => {
  const { pastDelay, error, timedOut, retry } = props
  const { user } = useContext(UserContext)

  // are we switching?
  const switching = window.location.pathname.includes("switch-employer")

  if (user && user.id && !switching) {
    return <Layout>{pastDelay && <LoadingBox />}</Layout>
  } else {
    return (
      <LoadingLayout>
        {pastDelay && <LoadingSpinner />}
        {error && <Message text="An error occurred" retry={retry} />}
        {timedOut && <Message text="Request timed out" retry={retry} />}
      </LoadingLayout>
    )
  }
}

export default Loading
