import logo from "../assets/logos/campleaders.svg"
import colouredLogo from "../assets/logos/campleaders_coloured.svg"

const campleaders = {
  name: "campleaders",
  friendlyName: "Camp Leaders",
  tagline: "Hire with the Leaders.",
  links: {
    appBase: {
      development: "http://campleaders.test:3001",
      staging: "https://emf-staging.campleaders.com",
      production: "https://camps.campleaders.com"
    }
  },
  hostWord: "Camp",
  logo: logo,
  colouredLogo: colouredLogo
}

export default campleaders
