import styled from "styled-components"

export const OptionsPopupOuter = styled.div`
  position: fixed;
  top: unset;
  bottom: 11rem;
  left: 14rem;
  z-index: 100;
  width: 33%;
  width: 33vw;
  width: 33dvw;
  max-width: 380px;
`
