import logo from "../assets/logos/resortleaders.svg"
import colouredLogo from "../assets/logos/resortleaders_coloured.svg"

const resortleaders = {
  name: "resortleaders",
  friendlyName: "Resort Leaders",
  tagline: "Hire with the Leaders.",
  links: {
    appBase: {
      development: "http://resortleaders.test:3001",
      staging: "https://emf-staging.resortleaders.com",
      production: "https://resorts.resortleaders.com"
    }
  },
  hostWord: "Resort",
  logo: logo,
  colouredLogo: colouredLogo
}

export default resortleaders
