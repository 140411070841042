export function parameterize(str = "", separator = "-") {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, separator)
}

export function toBool(value = "") {
  if (value === null || value === undefined) return false

  return ["true", "yes", "1"].includes(value.toString().trim().toLowerCase())
}

export function urlWithProtocol(str) {
  if (/^((http|https):\/\/)/.test(str)) return str
  else return "https://" + str
}

export function capitalize(str) {
  if (typeof str !== "string") return ""

  return initialise(str) + str.slice(1)
}

export function initialise(str) {
  if (typeof str !== "string") return ""

  return str.charAt(0).toUpperCase()
}

export function validVideoUrl(videoUrl) {
  const youtubePattern = /youtube\.com\/embed\/(.{11})(\?.{0,})?/
  const vimeoPattern = /player\.vimeo\.com.*(\?v=|\/video\/)(.{8,})/

  return youtubePattern.test(videoUrl) || vimeoPattern.test(videoUrl)
}

export function titleize(str) {
  if (typeof str !== "string") return ""

  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())
}

export function truncateNearestWord(str, maxLength) {
  try {
    let output = ""
    let currentLength = 0
    let insideTag = false
    let insideMarkdownLink = false
    let insideBold = false
    let tagBuffer = ""
    let markdownBuffer = ""
    let boldBuffer = ""

    for (let i = 0; i < str.length; i++) {
      const char = str[i]

      // Check if we're inside an HTML tag
      if (char === "<") {
        insideTag = true
        tagBuffer = "<"
        continue
      }

      if (insideTag) {
        tagBuffer += char
        if (char === ">") {
          insideTag = false
          output += tagBuffer
          tagBuffer = ""
        }
        continue
      }

      // Check if we're inside a Markdown link
      if (char === "[") {
        insideMarkdownLink = true
        markdownBuffer = "["
        continue
      }

      if (insideMarkdownLink) {
        markdownBuffer += char
        if (char === "]") {
          let linkPart = ""
          let j = i + 1
          if (str[j] === "(") {
            while (j < str.length && str[j] !== ")") {
              linkPart += str[j]
              j++
            }
            if (str[j] === ")") {
              markdownBuffer += linkPart + ")"
              output += markdownBuffer
              i = j
              insideMarkdownLink = false
              markdownBuffer = ""
              continue
            }
          }
        }
        continue
      }

      // Check if we're inside a bold tag
      if (char === "*" && str[i + 1] === "*") {
        if (!insideBold) {
          insideBold = true
          boldBuffer = "**"
          i++ // Skip the second asterisk
          continue
        } else {
          insideBold = false
          output += boldBuffer + "**"
          boldBuffer = ""
          i++ // Skip the second asterisk
          continue
        }
      }

      if (insideBold) {
        boldBuffer += char
        continue
      }

      // Add characters to the output while under the limit
      if (currentLength < maxLength) {
        output += char
        currentLength++
      } else {
        // We've reached the limit - find the last word boundary
        const lastSpaceIndex = output.lastIndexOf(" ")
        if (lastSpaceIndex !== -1) {
          output = output.substring(0, lastSpaceIndex)
        }

        // Handle any unclosed tags/formatting
        if (insideTag) {
          output += ">"
        }
        if (insideMarkdownLink && markdownBuffer) {
          // Don't include incomplete markdown links
          output = output.replace(markdownBuffer, "")
        }
        if (insideBold && boldBuffer) {
          output += boldBuffer + "**"
        }
        break
      }
    }

    // Clean up any trailing incomplete markdown elements
    if (markdownBuffer && !markdownBuffer.includes("]")) {
      output = output.replace(markdownBuffer, "")
    }

    // If we end with an unclosed bold tag, close it
    if (insideBold) {
      output += boldBuffer + "**"
    }

    return output.trim()
  } catch (error) {
    console.error("truncateNearestWord error", error)
    // Fallback to simple word boundary truncation
    let trimmedString = str.substr(0, maxLength)
    const lastSpaceIndex = trimmedString.lastIndexOf(" ")
    return lastSpaceIndex !== -1 ? trimmedString.substr(0, lastSpaceIndex) : trimmedString
  }
}

export function setGradientBackgroundColor(theme) {
  return `linear-gradient(135deg, ${theme.colors.headerGradient.start} 0%, ${theme.colors.headerGradient.end} 100%)`
}
