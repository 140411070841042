import React, { Component } from "react"
import UserContext from "@context/UserContext"
import Layout from "@layouts/layout"
import {
  Header,
  Form,
  FieldGroup,
  Label,
  TextField,
  SelectField,
  SubmitButton,
  HelpText,
  Grid,
  TextArea
} from "@smaller-earth/postcard-components"
import { patchRequest } from "@helpers/apiHelpers"
import { decliningReasons, rejectionReasons } from "@helpers/dataHelpers"
import { titleize } from "@helpers/stringHelpers"
import { withAlert } from "react-alert"

const OTHER_STRING = "Other"

class DeclinePlacement extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    if (!props.history.location.state?.id) {
      props.history.push("/placements")
    }

    this.state = {
      status_reason: "",
      view: "",
      ...this.props.history.location.state
    }
  }

  handleInputChange = (name, value) => {
    const { view } = this.state

    let updates = { [name]: value }
    if (name === "status_reason") {
      updates["selected_bespoke_reason"] = value === OTHER_STRING

      if (updates["selected_bespoke_reason"]) {
        updates["bespoke_reason"] = ""
      } else {
        const shouldPrefill =
          !this.state.status_shareable_from_employer_changed || this.state.status_shareable_from_employer === ""
        if (shouldPrefill) {
          if (value && value.length > 0) {
            let action
            if (view === "decline_interview") action = "this interview has been declined"
            else if (view === "cancel_interview") action = "this interview has been cancelled"
            else if (view === "decline_placement") action = "this placement has been declined"
            else if (view === "cancel_offer") action = "this placement offer has been cancelled"
            else action = "we won't be able to proceed"

            updates["status_shareable_from_employer"] = `Sorry, ${action}.  Reason: ${value}.\n\nBest of luck!`
          } else {
            updates["status_shareable_from_employer"] = ""
          }

          updates["status_shareable_from_employer_changed"] = false
        }
      }
    } else if (name === "status_shareable_from_employer") {
      updates["status_shareable_from_employer"] = value
      updates["status_shareable_from_employer_changed"] = true
    }

    this.setState(updates)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ submitting: true }, () => {
      const { view } = this.state
      this.placementAction(view)
    })
  }

  reason = () => {
    return this.state.selected_bespoke_reason ? this.state.bespoke_reason : this.state.status_reason
  }

  placementAction = (view) => {
    const { current_employer_id, access_token } = this.context.user
    const { id, redirectBack } = this.state
    const params = JSON.stringify({
      employers_interview: {
        employer_action: view,
        status_reason: this.reason(),
        status_shareable_from_employer: this.state.status_shareable_from_employer || ""
      }
    })

    patchRequest(
      `/employers/employers/${current_employer_id}/interviews/${id}`,
      access_token,
      params,
      "v2",
      this.context
    )
      .then((data) => {
        let alert_message
        if (view === "decline_interview") alert_message = "Interview has been declined"
        else if (view === "cancel_interview") alert_message = "Interview has been cancelled"
        else if (view === "decline_placement") alert_message = "Placement has been declined"
        else if (view === "cancel_offer") alert_message = "Placement offer has been cancelled"

        this.props.alert.success(alert_message)
        this.props.history.push(`/placements/${redirectBack}`)
      })
      .catch((error) => this.props.alert.error(error.message))
      .finally(() => this.setState({ submitting: false }))
  }

  render() {
    const {
      status_reason,
      selected_bespoke_reason,
      bespoke_reason,
      status_shareable_from_employer,
      submitting,
      view,
      redirectBack
    } = this.state
    const { handleInputChange, handleSubmit } = this
    const reasons = ["decline_interview", "cancel_interview"].includes(view)
      ? decliningReasons(OTHER_STRING)
      : rejectionReasons(OTHER_STRING)

    return (
      <Layout title="Placements" simpleFormLayout={true}>
        <Header title={titleize(view.replace(/_/, " "))} simpleFormLayout={true} />
        <Form simpleForm>
          <Grid gap="1.25rem">
            <FieldGroup>
              <Label simpleForm>Reason</Label>

              <Grid gap="0.5rem">
                <SelectField
                  name="status_reason"
                  value={status_reason}
                  handleInputChange={handleInputChange}
                  options={reasons.map((reason) => [reason, reason])}
                  simpleForm
                />

                {selected_bespoke_reason && (
                  <TextField
                    name="bespoke_reason"
                    value={bespoke_reason}
                    handleInputChange={handleInputChange}
                    placeholder="Please specify"
                    maxLength="255"
                    simpleForm
                  />
                )}
              </Grid>
            </FieldGroup>

            <FieldGroup>
              <Label simpleForm>Message</Label>

              <TextArea
                name="status_shareable_from_employer"
                value={status_shareable_from_employer}
                handleInputChange={handleInputChange}
                placeholder="Add your message to the participant here.  Optional."
                maxLength="255"
                simpleForm
              />

              <HelpText simpleForm>This message will be used to help provide the participant with feedback.</HelpText>
            </FieldGroup>

            <SubmitButton
              disabled={submitting}
              backButton={{ to: `placements/${redirectBack}` }}
              handleClick={handleSubmit}
              simpleForm
            />
          </Grid>
        </Form>
      </Layout>
    )
  }
}

export default withAlert()(DeclinePlacement)
