import React, { useState, useMemo, useCallback } from "react"
import Routes from "../../routes"

import { getEnvironment, getBrand, getTheme } from "@helpers/siteHelpers"
import { getRequest } from "@helpers/apiHelpers"
import { getBrandFromId } from "@helpers/dataHelpers"
import { ThemeProvider } from "styled-components"
import { PostcardThemeProvider, SimpleAlert } from "@smaller-earth/postcard-components"
import { BrowserRouter } from "react-router-dom"
import { BrandProvider } from "@context/BrandContext"
import { UserProvider } from "@context/UserContext"
import { positions as alertPositions, Provider as AlertProvider } from "react-alert"

import TagManager from "react-gtm-module"

import { isEmpty } from "lodash"
import { useCookies } from "react-cookie"

import { clarity } from "react-microsoft-clarity"
import * as Fathom from "fathom-client"

const ClarityId = "mtddbgclh6"
const GtmId = "GTM-5JGZ3J5S"
const FathomSiteId = "INUAKNMR"

const alertOptions = {
  timeout: 5000,
  position: alertPositions.BOTTOM_RIGHT
}

const theme = {
  ...getTheme(),
  breakpoints: {
    mobile: 0,
    tablet: 769,
    desktop: 1025,
    widescreen: 1216,
    fullhd: 1408,
    mahoosive: 1600
  }
}

const home = { name: "Home", path: "/" }
const separator = { separator: true }
const notifications = { name: "Notifications", path: "/notifications" }
const settings = { name: "Settings", path: "/settings" }

const App = () => {
  const [cookies] = useCookies(["authState"])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})
  const [menu, setMenu] = useState({
    checklist: []
  })
  const [passwordUpdateRequired, setPasswordUpdateRequired] = useState(false)

  const parseMenu = (data, user) => {
    const { current_employer_id } = user

    const employers = data.employers
    employers.forEach((employer) => {
      employer.brand = getBrandFromId(employer.channel_id)
    })
    let currentEmployer = employers.find((employer) => employer.id === current_employer_id)
    if (currentEmployer === undefined) currentEmployer = null

    return {
      employer: currentEmployer,
      employers: employers,
      helpSupportUrl: data.help_support_url,
      communityUrl: data.community_url,
      messagingEnabled: data.messaging_enabled,
      messageCount: data.message_count,
      notificationCount: data.notification_count,
      staffCounts: data.staff_counts,
      showFeeSheet: data.show_fee_sheet
    }
  }

  const parseChecklist = (data) => {
    let additionalLinks = []

    if (data.checklist.length > 0) {
      const incomplete_sections = data.checklist.filter((section) => section.percentage !== 100)
      const checklist = {
        name: `${getBrand().hostWord} Information`,
        status: data.profile_status,
        items: data.checklist,
        initiallyExpanded: data.profile_status !== "complete",
        profile_position: "outside",
        seperator: true,
        system_name: "information",
        completed: data.profile_status === "complete",
        link: incomplete_sections.length > 0 ? incomplete_sections[0].path : ""
      }

      additionalLinks.push(...[separator, checklist, separator, notifications])
    }

    if (data.messaging_enabled) {
      additionalLinks.push({
        name: "Messages",
        path: "/conversations",
        counter: data.message_count
      })
    }
    additionalLinks.push(settings)

    let menuItems = [].concat([home, separator], data.links, additionalLinks)
    if (data.help_support_url) menuItems.push({ name: "Help", path: data.help_support_url })
    return menuItems
  }

  const getMenuInfo = useCallback(() => {
    const { current_employer_id, access_token, current_user_masquerading } = user
    getRequest(
      `/employers/employers/${current_employer_id}/menu?&is_user_masquerading=${current_user_masquerading}`,
      access_token
    )
      .then((data) => {
        setMenu({
          ...parseMenu(data, user),
          checklist: parseChecklist(data)
        })

        sessionStorage["currentEmployerId"] = data.employer_id
      })
      .catch((error) => console.error(error))
  }, [user])

  useMemo(() => {
    const sessionAuthState = sessionStorage["authState"]
    let authState
    let isAuthenticated = false
    let user = {}

    const setTracker = (user = null) => {
      if (isLoaded) return

      const environment = getEnvironment()
      if (environment !== "production") return

      // ignore if masquerading
      if (user && user.current_user_masquerading) return

      // init fathom
      Fathom.load(FathomSiteId, { spa: "auto" })

      // init clarity
      clarity.init(ClarityId)
      if (user && user.id && clarity.hasStarted()) {
        clarity.identify(`${user.id}`, {
          email: user.email,
          employer_id: user.current_employer_id || "",
          user_masquerading: user.current_user_masquerading || ""
        })
      }

      // init GTM
      if (window.dataLayer) return
      TagManager.initialize({
        gtmId: GtmId,
        dataLayer:
          user && user.id
            ? {
                userId: user.id,
                email: user.email
              }
            : {}
      })
    }

    if (cookies.authState !== undefined) {
      // We are logged in
      authState = cookies.authState
      isAuthenticated = authState.isAuthenticated
      user = authState.user
    }

    if (!isAuthenticated && sessionAuthState) {
      // We are masquerading
      authState = JSON.parse(sessionAuthState)
      isAuthenticated = authState.isAuthenticated
      user = authState.user
    }

    if (authState) {
      const { passwordUpdateRequired } = authState

      setIsAuthenticated(isAuthenticated)
      setUser(user)
      setTracker(user)
      setPasswordUpdateRequired(passwordUpdateRequired)
    } else {
      setTracker()
    }

    setIsLoaded(true)
  }, [isLoaded, setIsLoaded, cookies])

  useMemo(() => {
    if (!user || isEmpty(user) || !isAuthenticated) return

    const switchedEmployerId = parseInt(cookies.switchedEmployerId)
    const currentEmployerId = parseInt(sessionStorage["currentEmployerId"])
    if (switchedEmployerId > 0 && currentEmployerId > 0 && currentEmployerId !== switchedEmployerId) {
      // Have we switched employer in another tab?
      sessionStorage["currentEmployerId"] = switchedEmployerId
      setTimeout(() => window.location.reload(), 750)
    }

    getMenuInfo()
  }, [user, getMenuInfo, isAuthenticated, cookies])

  return (
    <>
      {!isLoaded && <></>}

      {isLoaded && (
        <BrandProvider value={getBrand()}>
          <ThemeProvider theme={theme}>
            <PostcardThemeProvider theme={theme}>
              <AlertProvider template={SimpleAlert} {...alertOptions}>
                <UserProvider value={{ user, menu, setUser, setMenu }}>
                  <BrowserRouter>
                    <Routes isAuthenticated={isAuthenticated} passwordUpdateRequired={passwordUpdateRequired} />
                  </BrowserRouter>
                </UserProvider>
              </AlertProvider>
            </PostcardThemeProvider>
          </ThemeProvider>
        </BrandProvider>
      )}
    </>
  )
}

export default App
