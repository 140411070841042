import logo from "../assets/logos/adventurechina.svg"
import colouredLogo from "../assets/logos/adventurechina_coloured.svg"

const adventurechina = {
  name: "adventurechina",
  friendlyName: "Adventure China",
  tagline: "Find your future staff.",
  links: {
    appBase: {
      development: "http://adventurechina.test:3001",
      staging: "https://emf-staging.adventurechina.com",
      production: "https://camps.adventurechina.com"
    }
  },
  hostWord: "Camp",
  logo: logo,
  colouredLogo: colouredLogo
}

export default adventurechina
