import React from "react"
import { OptionsPopupOuter } from "./styles"
import { OptionsPopup } from "@smaller-earth/postcard-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const EmployerSwitcher = (props) => {
  const { currentEmployer, employers, className, changeEmployer } = props

  return (
    <>
      {currentEmployer && employers.length > 0 && (
        <OptionsPopupOuter>
          <OptionsPopup.Box className={className}>
            <OptionsPopup.List>
              {employers.map((employer) => (
                <OptionsPopup.ListItem>
                  <OptionsPopup.Link id={employer.id} brand={employer.brand} onClick={changeEmployer}>
                    <OptionsPopup.LinkText>
                      <span>{employer.name}</span>
                    </OptionsPopup.LinkText>

                    {employer.id === currentEmployer.id && <FontAwesomeIcon icon={faCheck} />}
                  </OptionsPopup.Link>
                </OptionsPopup.ListItem>
              ))}
            </OptionsPopup.List>
          </OptionsPopup.Box>
        </OptionsPopupOuter>
      )}
    </>
  )
}

export default EmployerSwitcher
