import styled, { css, createGlobalStyle } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useContext } from "react"
import MenuContext from "@context/MenuContext"

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100vh;
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.body.family}, sans-serif;
    font-weight: ${({ theme }) => theme.fonts.body.weight};
    margin: 0;
    padding: 0;
    font-size: 16px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @supports (-webkit-touch-callout: none) {
      html {
        height: -webkit-fill-available !important;
      }
    }
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    margin: 0;
    padding: 0;

    ${breakpoint("desktop")`
      background-color: ${(props) => props.theme.colors.background};
      overflow-x: hidden;
    `}
  }
`

export const Container = styled.div`
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available !important;
  }

  ${breakpoint("tablet")`
    display: flex;
  `}
`

export const Aside = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
  height: 100dvh;
  z-index: 50;
  overflow-y: auto;
  font-size: 1em;
  left: 100%;
  left: 100vw;
  left: 100dvw;
  transition: left 0.3s ease-in-out;
  width: 100%;
  width: 100dvw;
  position: fixed;

  ${(props) =>
    props.show &&
    css`
      left: 0;
    `}

  ${breakpoint("widescreen")`
    display: block;
    top: 0;
    left: 0;
    width: 36%;
    width: 36dvw;
    max-width: 360px;
    min-width: 270px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    position: sticky;
  `}
`

export const Main = styled.main`
  ${({ simpleForm }) =>
    !simpleForm &&
    css`
      padding: 2rem 1rem 12rem 1rem;

      ${breakpoint("tablet")`
        padding: 3rem 1rem 6rem 1rem;
      `}
    `}

  ${({ simpleForm }) =>
    !!simpleForm &&
    css`
      padding: 2rem 24px 12rem 24px;
      max-width: 89%;
      max-width: 89vw;
    `}

  ${breakpoint("desktop")`
    padding: 0 0 3rem 0;

    ${({ simpleForm }) =>
      !!simpleForm &&
      css`
        max-width: 672px;
        margin: 2rem auto;
      `}
  `}

  grid-area: main;
  overflow-y: auto;
  padding-bottom: 8rem;
`

export const CloseButton = styled.div`
  text-align: right;
  user-select: none;
  display: block;

  ${breakpoint("widescreen")`
    display: none;
  `}
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Inner = ({ children }) => {
  const { showMobileEmployerList } = useContext(MenuContext)
  return <InnerWrapper showMobileEmployerList={showMobileEmployerList}>{children}</InnerWrapper>
}

const InnerWrapper = styled.div`
  position: relative;
  left: 0;
  transition: left 0.3s ease-in-out;

  ${(props) =>
    props.showMobileEmployerList &&
    css`
      left: -100%;
      left: -100dvw;
    `}

  ${breakpoint("widescreen")`
    height: 100vh;
    display: flex;
    flex-direction: column;
  `}
`

export const Nav = styled.div`
  padding: 1.2rem;

  ${breakpoint("widescreen")`
    padding: 2rem 1.375rem 2rem 0.5rem;
    flex: 1 1 auto;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
    height: 0px;
  `}

  &::-webkit-scrollbar {
    width: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.primary};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryContrastedDarker};
  }
`
export const MainWrapper = styled.div`
  width: 100%;

  ${breakpoint("desktop")`
    width: 65%;
    width: 65vw;
    width: 65dvw;
    padding: 2rem;
    flex-grow: 1;
  `}
`
