import React from "react"
import PropTypes from "prop-types"
import { Button } from "@smaller-earth/postcard-components"
import { Container, Text } from "./styles"

const Message = ({ text, retry }) => (
  <Container>
    <Text>{text}</Text>
    {retry && <Button text="Retry" onClick={retry} white outlined collapsed />}
  </Container>
)

Message.propTypes = {
  text: PropTypes.string.isRequired,
  retry: PropTypes.func
}

export default Message
