import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.div`
  display: block;
  width: 100%;
  width: 100dvw;
  height: 100dvh;
  z-index: 50;
  overflow-y: auto;
  font-size: 1.2em;
  position: fixed;
  top: 0
  left: 100%;
  left: 100dvw;
  transition: left 0.3s ease-in-out;
  color: #fff;


  ${(props) =>
    props.show &&
    css`
      left: 0;
    `}

  ${breakpoint("desktop")`
    display: none;
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.2em;
  margin: 0 0 2em;
`
export const CancelLink = styled.a`
  display: flex;
  gap: 0.4rem;
  justify-content: start;
  align-items: center;
`

export const EmployerLink = styled.a`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.active &&
    css`
      & > span {
        font-weight: bold;
      }
    `}
`
