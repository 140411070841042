export const countries = [
  { id: 248, country: "United Kingdom", country_phone_code: "44" },
  { id: 224, country: "United States", country_phone_code: "1" }
]

export function countriesForSelect() {
  let arr = []

  countries.forEach((country) => {
    arr.push([country.id, country.country])
  })

  return arr
}

export function phoneCodesForSelect() {
  let arr = []

  countries.forEach((country) => {
    arr.push([country.id, country.country_phone_code])
  })

  return arr
}

export const genders = [
  { name: "Female", code: "f" },
  { name: "Male", code: "m" },
  { name: "Other", code: "x" }
]

export function gendersForSelect() {
  let arr = []

  genders.forEach((gender) => {
    arr.push([gender.code, gender.name])
  })

  return arr
}

export const programmeMap = {
  1: "FT Counselor"
}

export function getProgrammeFromId(id) {
  return programmeMap[1]
}

export const channelMap = {
  3: "campleaders",
  4: "canago",
  5: "smallerearth",
  9: "resortleaders",
  11: "adventurechina",
  17: "adventureasia",
  12: "campcanada",
  16: "americancampco"
}

export function getBrandFromId(id) {
  return channelMap[id]
}

export function currentSeason() {
  const now = new Date()
  // 8 = September
  return now.getMonth() >= 8 ? now.getFullYear() + 1 : now.getFullYear()
}

export function lastSeason(convertToFinancial = true) {
  if (convertToFinancial) {
    return convertToFinancialYear(currentSeason() - 1)
  } else {
    return currentSeason() - 1
  }
}

export const DAWN_OF_TIME = 2015 // This should match Site::DAWN_OF_TIME on Postcard

export function seasonOptions(includeThisSeason = false, includeAllSeasons = false) {
  let options = generateYearsBetween(DAWN_OF_TIME, currentSeason()).reverse()

  let extraOptions = !!includeThisSeason ? ["This season"] : []
  if (!!includeAllSeasons) extraOptions.push("All Years")

  if (extraOptions.length > 0) return [...extraOptions, ...options]
  else return options
}

export function seasonOptionByYears(previousYears, includeThisSeason = false, includeAllSeasons = false) {
  let options = []

  if (!!includeThisSeason) {
    options = generateYearsBetween(currentSeason() - 1 - previousYears, currentSeason() - 1).reverse()
  } else {
    options = generateYearsBetween(currentSeason() - previousYears, currentSeason()).reverse()
  }

  let extraOptions = !!includeThisSeason ? ["This season"] : []
  if (!!includeAllSeasons) extraOptions.push("All Years")

  if (extraOptions.length > 0) return [...extraOptions, ...options]
  else return options
}

export function seasonFinanceOptionByYears(previousYears) {
  let years = generateYearsBetween(currentSeason() - previousYears, currentSeason()).reverse()
  return years.map((value) => convertToFinancialYear(value))
}

export function convertToFinancialYear(year) {
  return String(year - 1) + "/" + year
}

export function generateYearsBetween(startYear, endYear) {
  let years = []

  for (var i = startYear; i <= endYear; i++) {
    years.push(startYear)
    startYear++
  }

  return years
}

export function decliningReasons(string_for_other = "Other") {
  return rejectionReasons(string_for_other).filter((reason) => !reason.includes("interview"))
}

export function rejectionReasons(string_for_other = "Other") {
  return [
    "Did not pass the interview",
    "Insufficient level of English",
    "Issues with availability",
    "Lack of experience",
    "Missed interview",
    "Over-qualified",
    "Under-qualified",
    "Position filled",
    "Did not respond to employer outreach",
    string_for_other
  ]
}
