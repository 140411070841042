import logo from "../assets/logos/americancampco.svg"
import colouredLogo from "../assets/logos/americancampco_coloured.svg"

const americancampco = {
  name: "americancampco",
  friendlyName: "American Camp Co",
  tagline: "Find your future staff.",
  links: {
    appBase: {
      development: "http://americancampco.test:3001",
      staging: "https://emf-staging.americancampco.com",
      production: "https://camps.americancampco.com"
    }
  },
  hostWord: "Camp",
  logo: logo,
  colouredLogo: colouredLogo
}

export default americancampco
