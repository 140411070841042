import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import PublicRoute from "./public-route"
import PrivateRoute from "./private-route"
import Loadable from "react-loadable"
import Loading from "../pages/loading"
import DeclinePlacement from "../pages/decline-placement/DeclinePlacement"

const SwitchEmployer = Loadable({ loader: () => import("../pages/switch-employer"), loading: Loading })
const Masquerade = Loadable({
  loader: () => import("../pages/masquerade"),
  loading: Loading
})
const SignInWithToken = Loadable({
  loader: () => import("../pages/sign-in-with-token"),
  loading: Loading
})
const Login = Loadable({
  loader: () => import("../pages/login"),
  loading: Loading
})
const Logout = Loadable({ loader: () => import("../pages/logout"), loading: Loading })
const ResetPassword = Loadable({
  loader: () => import("../pages/reset-password"),
  loading: Loading
})
const Home = Loadable({
  loader: () => import("../pages/home"),
  loading: Loading
})
const ForgotPassword = Loadable({
  loader: () => import("../pages/forgot-password"),
  loading: Loading
})
const Notifications = Loadable({
  loader: () => import("../pages/notifications"),
  loading: Loading
})
const Financials = Loadable({
  loader: () => import("../pages/financials"),
  loading: Loading
})
const NewPayment = Loadable({
  loader: () => import("../pages/new-payment"),
  loading: Loading
})
const InterviewAvailability = Loadable({
  loader: () => import("../pages/interview-availability"),
  loading: Loading
})
const ConfirmPlacement = Loadable({
  loader: () => import("../pages/confirm-placement"),
  loading: Loading
})
const RejectPlacement = Loadable({
  loader: () => import("../pages/reject-placement"),
  loading: Loading
})
const Placements = Loadable({
  loader: () => import("../pages/placements"),
  loading: Loading
})
const StaffingNeeds = Loadable({
  loader: () => import("../pages/staffing-needs"),
  loading: Loading
})
const PublicProfile = Loadable({
  loader: () => import("../pages/public-profile"),
  loading: Loading
})
const StaffRatings = Loadable({
  loader: () => import("../pages/staff-ratings"),
  loading: Loading
})
const StaffSearch = Loadable({
  loader: () => import("../pages/staff-search"),
  loading: Loading
})
const BasicDetails = Loadable({
  loader: () => import("../pages/basic-details"),
  loading: Loading
})
const Address = Loadable({
  loader: () => import("../pages/address"),
  loading: Loading
})
const Contacts = Loadable({
  loader: () => import("../pages/contacts"),
  loading: Loading
})
const PreferredAirport = Loadable({
  loader: () => import("../pages/preferred-airport"),
  loading: Loading
})
const Agreements = Loadable({
  loader: () => import("../pages/agreements"),
  loading: Loading
})
const ArrivalDates = Loadable({
  loader: () => import("../pages/arrival-dates"),
  loading: Loading
})
const OnwardTravel = Loadable({
  loader: () => import("../pages/onward-travel"),
  loading: Loading
})
const Documents = Loadable({
  loader: () => import("../pages/documents"),
  loading: Loading
})
const Document = Loadable({
  loader: () => import("../pages/document"),
  loading: Loading
})
const ApplicationForm = Loadable({
  loader: () => import("../pages/application-form"),
  loading: Loading
})
const CulturalActivities = Loadable({
  loader: () => import("../pages/cultural-activities"),
  loading: Loading
})
const DirectHire = Loadable({
  loader: () => import("../pages/direct-hire"),
  loading: Loading
})
const Settings = Loadable({
  loader: () => import("../pages/settings"),
  loading: Loading
})
const Offer = Loadable({
  loader: () => import("../pages/offer"),
  loading: Loading
})
const Conversations = Loadable({
  loader: () => import("../pages/conversations"),
  loading: Loading
})
const Arrivals = Loadable({
  loader: () => import("../pages/arrivals"),
  loading: Loading
})
const FeeSheet = Loadable({
  loader: () => import("../pages/fee-sheet"),
  loading: Loading
})
const RecurringInterviewAvailability = Loadable({
  loader: () => import("../pages/recurring-interview-availability"),
  loading: Loading
})
const AcceptInterviewRequest = Loadable({
  loader: () => import("../pages/accept-interview-request"),
  loading: Loading
})
const DeclineInterviewRequest = Loadable({
  loader: () => import("../pages/decline-interview-request"),
  loading: Loading
})
const NotFound = Loadable({
  loader: () => import("../pages/not-found"),
  loading: Loading
})
const Forbidden = Loadable({
  loader: () => import("../pages/forbidden"),
  loading: Loading
})
const InternalServerError = Loadable({
  loader: () => import("../pages/internal-server-error"),
  loading: Loading
})
const PayrollData = Loadable({
  loader: () => import("../pages/payroll-data"),
  loading: Loading
})
const Community = Loadable({
  loader: () => import("../pages/community"),
  loading: Loading
})

const Routes = ({ isAuthenticated, passwordUpdateRequired }) => (
  <Switch>
    <PublicRoute exact path="/switch-employer/:token/:authmethod/:employerid" component={SwitchEmployer} />
    <PublicRoute exact path="/masquerade/:token" component={Masquerade} />
    <PublicRoute exact path="/sign-in-with-token/:token" component={SignInWithToken} />
    <PublicRoute exact path="/login" component={Login} />
    <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
    <PublicRoute exact path="/reset-password/:token" component={ResetPassword} />
    <PublicRoute exact path="/accept-interview-request/:token" component={AcceptInterviewRequest} />
    <PublicRoute exact path="/decline-interview-request/:token" component={DeclineInterviewRequest} />

    <PrivateRoute
      exact
      path="/"
      component={Home}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/notifications"
      component={Notifications}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/financials"
      component={Financials}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <Redirect exact from="/payments" to="/financials" />
    <PrivateRoute
      exact
      path="/payments/new"
      component={NewPayment}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/interview-availability"
      component={InterviewAvailability}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/placements/:id/confirm"
      component={ConfirmPlacement}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/placements/:id/reject"
      component={RejectPlacement}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/placements/:status"
      component={Placements}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/placements/:id/offer"
      component={Offer}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/decline-placement"
      component={DeclinePlacement}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <Redirect exact from="/placements" to="/placements/placed" />
    <PrivateRoute
      exact
      path="/staffing-needs"
      component={StaffingNeeds}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/public-profile"
      component={PublicProfile}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <Redirect exact from="/staff-ratings" to="/feedback/employer" />
    <Redirect exact from="/feedback" to="/feedback/employer" />
    <PrivateRoute
      exact
      path="/feedback/employer"
      component={StaffRatings}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/feedback/staff"
      component={StaffRatings}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/staff-search"
      component={StaffSearch}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/basic-details"
      component={BasicDetails}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/address"
      component={Address}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/contacts"
      component={Contacts}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/preferred-airport"
      component={PreferredAirport}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/agreements"
      component={Agreements}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/arrival-dates"
      component={ArrivalDates}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/onward-travel"
      component={OnwardTravel}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/documents"
      component={Documents}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/documents/:id"
      component={Document}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/application-forms/:id"
      component={ApplicationForm}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/cultural-activities"
      component={CulturalActivities}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <Redirect exact from="/j1-visa-cultural-activities" to="/cultural-activities" />
    <PrivateRoute
      exact
      path="/direct-hire"
      component={DirectHire}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/settings"
      component={Settings}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/conversations"
      component={Conversations}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/arrivals"
      component={Arrivals}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/fee-sheet"
      component={FeeSheet}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/recurring-interview-availability"
      component={RecurringInterviewAvailability}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/payroll-data"
      component={PayrollData}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute
      exact
      path="/community"
      component={Community}
      isAuthenticated={isAuthenticated}
      passwordUpdateRequired={passwordUpdateRequired}
    />
    <PrivateRoute exact path="/403" component={Forbidden} />
    <PrivateRoute exact path="/404" component={NotFound} />
    <PrivateRoute exact path="/500" component={InternalServerError} />
    <Route exact path="/logout" component={Logout} />
    <Route component={NotFound} />
  </Switch>
)

export default Routes
